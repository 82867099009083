import { track } from "@amplitude/analytics-browser";
import React from "react";
import { useRouteError } from "react-router-dom";

type errorType = {
  statusText?: string;
  message?: string;
};

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  const errorMsg = error as errorType;

  const clickDownloadApp = () => {
    track("web - click-download-app");
    window.open("https://peekabond.com/try-peekabond", "_blank");
  };

  return (
    <>
      <header className="header">
        <img className="logo" src={require("./assets/images/logo.png")}></img>
        <a onClick={clickDownloadApp} className="button">
          Get the App
        </a>
      </header>
      <main
        style={{
          display: "flex",
          flex: 1,
          background: "#fcf9f4",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: 250 }}>
          <h2 style={{ color: "#333374" }} className="title">
            Oops!
          </h2>
          <p style={{ color: "#333374" }} className="paragraph">
            Sorry, an unexpected error has occurred.
          </p>
          <p style={{ color: "#333374" }} className="paragraph">
            <i>{errorMsg.statusText || errorMsg.message}</i>
          </p>
        </div>
      </main>
    </>
  );
}
