import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Player from "./Player";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./errorPage";
import { init } from "@amplitude/analytics-browser";
import { HelmetProvider } from "react-helmet-async";
import Home from "./Home";

init("61b33bb458d0a182d33fe5fe12d39839");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/app/:slug",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/v/:slug",
    element: <Player />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);
