import React from "react";
import "../App.css";
import { track } from "@amplitude/analytics-browser";

interface popupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: any;
}

function InstallAppPromo(props: popupProps) {
  const clickDownloadApp = () => {
    track("web - click-download-app");
    window.open("https://peekabond.onelink.me/lJOg/tl0murd6", "_blank");
  };

  return (
    <>
      <main
        style={{
          position: "fixed",
          inset: "0 0 0 0",
          background: "rgba(0,0,0,0.3)",
          zIndex: "99999",
        }}
        onClick={props.onClose}
      >
        <div
          style={{
            background: "#fff",
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            alignContent: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "center",
            paddingBottom: 20,
          }}
        >
          <img
            src={require("../assets/images/logo_square.png")}
            style={{ width: "100px", marginTop: 20, marginBottom: 20 }}
          />
          <h2
            style={{ color: "#333374", marginTop: 0, fontSize: 20 }}
            className="title"
          >
            Watch this on the Peekabond App
          </h2>
          <p
            style={{ color: "#333374", marginBottom: 30 }}
            className="paragraph"
          >
            For the best experience, watch and record your response with the
            Peekabond app. It&apos;s FREE!
          </p>
          <a
            style={{ margin: 30, fontSize: 18 }}
            onClick={clickDownloadApp}
            className="button-promo"
          >
            Try the Peekabond App
          </a>
          <a
            style={{ margin: 30, fontSize: 18 }}
            onClick={props.onClose}
            className="button-later"
          >
            Not now
          </a>
        </div>
      </main>
    </>
  );
}

export default InstallAppPromo;
