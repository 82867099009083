import React from "react";
import "./App.css";
import { useParams } from "react-router-dom";
import { track } from "@amplitude/analytics-browser";
import { Helmet } from "react-helmet-async";

function App() {
  const { slug } = useParams();
  const link = `pkbnd://app/${slug}`;

  const clickDownloadApp = () => {
    track("web - click-download-app");
    window.open("https://peekabond.com/try-peekabond", "_blank");
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Your magic sign-in link" />
        <title>Let us sign you in</title>
      </Helmet>
      <header className="header">
        <img className="logo" src={require("./assets/images/logo.png")}></img>
        <a onClick={clickDownloadApp} className="button">
          Get the App
        </a>
      </header>
      <main
        style={{
          display: "flex",
          flex: 1,
          background: "#fcf9f4",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: 250 }}>
          <h2 style={{ color: "#333374" }} className="title">
            Launching Peekabond...
          </h2>
          <p style={{ color: "#333374" }} className="paragraph">
            If you open this link on your mobile device, the Peekabond app
            should open automatically.
          </p>
          <p style={{ color: "#333374" }} className="paragraph">
            Click the button if the app doesn&apos;t open automatically.
          </p>
          <a
            style={{ margin: "30px", fontSize: 14 }}
            className="button"
            href={link}
          >
            Open Peekabond Now
          </a>
        </div>
      </main>
    </>
  );
}

export default App;
