import axios from "axios";

const Api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || "https://api-staging.pkbnd.com/api/v1/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default Api;
