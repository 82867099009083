import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import { Stream } from "@cloudflare/stream-react";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import love from "./assets/animations/love-explosion.json";
import Api from "./Api";
import { track } from "@amplitude/analytics-browser";
import { Helmet } from "react-helmet-async";
import { IoIosHeart, IoIosShareAlt } from "react-icons/io";
import InstallAppPromo from "./components/InstallAppPromo";

function Player() {
  const { slug } = useParams();
  // eslint-disable-next-line
  const lottiePlayer: any = useRef();
  const [lottieSrc, setLottieSrc] = useState();
  const [videoSrc, setVideoSrc] = useState("");
  const [mediaReady, setMediaReady] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [time, setTime] = useState(30);
  const [thumbnail, setThumbnail] = useState("");
  const [showPopup, setShowPopup] = useState(true);

  const videoPlayer: any = useRef();

  const timer = setTimeout(() => {
    if (time > 0) {
      setTime(time - 1);
    } else {
      location.reload();
    }
  }, 1000);

  const url = "post?slug=" + slug;
  Api.get(url)
    .then(({ data }) => {
      setVideoSrc(data.result.post.cloudflareUid);
      setMediaReady(data.result.post.mediaReady);
      const thumbnailUrl = `https://videodelivery.net/${videoSrc}/thumbnails/thumbnail.jpg?5s&width=600&height=600`;
      setThumbnail(thumbnailUrl);
      if (mediaReady) {
        clearTimeout(timer);
      }
      console.log("done");
    })
    .catch((e) => {
      console.error(e);
      setNotFound(true);
    });

  useEffect(() => {
    lottiePlayer.current.goToAndPlay(0);
  }, [lottieSrc]);

  const clickReact = (anim) => {
    track("web - share-love");
    Api.post(`post/love?slug=${slug}`)
      .then(() => {
        if (!isPlaying || anim === lottieSrc) {
          setLottieSrc(anim);
        }
        if (anim === lottieSrc) {
          lottiePlayer.current.goToAndPlay(0);
        }
        setIsPlaying(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const lottieDone = () => {
    lottiePlayer.current.goToAndStop(0);
    setIsPlaying(false);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const clickDownloadApp = () => {
    track("web - click-download-app");
    window.open("https://peekabond.onelink.me/lJOg/tl0murd6", "_blank");
  };

  const clickOpenApp = () => {
    track("web - click-open-app");
    const url = "pkbnd://play/" + slug;
    window.open(url);
  };

  const handleOnVideoEnded = () => {
    // videoPlayer.current.play();
  };

  const link = window.location.toString();

  return (
    <>
      <Helmet>
        <meta property="og:title" content="You have a new message" />
        <meta
          property="og:description"
          content="A loved one has made a video just for you, check it out!"
        />
        <title>You have a new message</title>

        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:locale" content="en_US" />

        <meta property="og:site_name" content="Peekabond" />
        <meta property="og:url" content={link} />
        <meta property="og:image" content={thumbnail} />
        <meta property="og:title" content="I just made a video just for you." />
        <meta property="og:description" content=""></meta>
        <meta name="apple-itunes-app" content="app-id=1571918169"></meta>
      </Helmet>
      {showPopup && <InstallAppPromo onClose={closePopup} />}
      <Lottie
        className="lottie-box"
        animationData={lottieSrc}
        loop={false}
        autoplay={false}
        lottieRef={lottiePlayer}
        onComplete={lottieDone}
      />
      <header className="header-player">
        <img
          src={require("./assets/images/logo_white.png")}
          style={{
            width: "100px",
            height: "20px",
            marginLeft: "10px",
            marginTop: "5px",
          }}
        ></img>
        <a onClick={clickDownloadApp} className="button-player">
          Get Peekabond
        </a>
      </header>
      <main className="container">
        <div className="video-box">
          {!mediaReady && (
            <div className="waiting-box">
              <div className="circle-wrap">
                <div className="circle">
                  <div className="mask full">
                    <div className="fill"></div>
                  </div>
                  <div className="mask half">
                    <div className="fill"></div>
                  </div>
                  <div className="inside-circle"></div>
                </div>
              </div>
              <h4 className="title">
                Little elves are still preparing your video
              </h4>
              <p className="paragraph">Retrying in {time} seconds...</p>
            </div>
          )}
          {notFound && (
            <div className="waiting-box">
              <h4 style={{ marginTop: 100 }} className="title">
                Video not found...
              </h4>
              <p className="paragraph">Did you use the right link?</p>
            </div>
          )}
          {mediaReady && videoSrc !== "" && (
            <Stream
              className="video-player"
              streamRef={videoPlayer}
              controls={true}
              src={videoSrc}
              onEnded={handleOnVideoEnded}
            />
          )}
        </div>
        <div
          style={{
            position: "absolute",
            left: "5px",
            top: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <a
            onClick={() => {
              clickReact(love);
            }}
            className="action-button"
          >
            <span
              style={{
                color: "#fff",
                fontFamily: "Merriweather Sans",
                fontSize: "12px",
                marginRight: "5px",
              }}
            >
              Love this
            </span>
            <IoIosHeart color="#fff" size={24} />
          </a>
          <a
            onClick={() => {
              clickOpenApp();
            }}
            className="action-button"
            style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
          >
            <span
              style={{
                color: "#fff",
                fontFamily: "Merriweather Sans",
                fontSize: "12px",
                marginRight: "5px",
              }}
            >
              Open App
            </span>
            <IoIosShareAlt color="#fff" size={24} />
          </a>
        </div>
      </main>

      {/*
      <main className="container">
        <div className="outer-box">
          <div className="inner-box">
            <div className="button-box">
              <a
                onClick={() => {
                  clickReact(love);
                }}
                className="img-button"
              >
                <img
                  className={isPlaying ? "disabled" : ""}
                  src={require("./assets/images/btn-love.png")}
                ></img>
              </a>
              <a
                onClick={() => {
                  clickReact(balloons);
                }}
                className="img-button"
              >
                <img
                  className={isPlaying ? "disabled" : ""}
                  src={require("./assets/images/btn-balloon.png")}
                ></img>
              </a>
              <a
                onClick={() => {
                  clickReact(confetti);
                }}
                className="img-button"
              >
                <img
                  className={isPlaying ? "disabled" : ""}
                  src={require("./assets/images/btn-fireworks.png")}
                ></img>
              </a>
              <a
                onClick={() => {
                  clickReact(stars);
                }}
                className="img-button"
              >
                <img
                  className={isPlaying ? "disabled" : ""}
                  src={require("./assets/images/btn-stars.png")}
                ></img>
              </a>
              </div>
            <div className="video-box">
              <div
                style={{
                  overflow: "hidden",
                  borderRadius: 15,
                  width: "100%",
                }}
              >
                {!mediaReady && (
                  <div className="waiting-box">
                    <div className="circle-wrap">
                      <div className="circle">
                        <div className="mask full">
                          <div className="fill"></div>
                        </div>
                        <div className="mask half">
                          <div className="fill"></div>
                        </div>
                        <div className="inside-circle"></div>
                      </div>
                    </div>
                    <h4 className="title">
                      Little elves are still preparing your video
                    </h4>
                    <p className="paragraph">Retrying in {time} seconds...</p>
                  </div>
                )}
                {notFound && (
                  <div className="waiting-box">
                    <h4 style={{ marginTop: 100 }} className="title">
                      Video not found...
                    </h4>
                    <p className="paragraph">Did you use the right link?</p>
                  </div>
                )}
                {mediaReady && videoSrc !== "" && (
                  <Stream controls src={videoSrc} />
                )}
              </div>
            </div>
            <div className="promo-box">
              <img
                className="promo-item"
                onClick={clickReactionPromo}
                style={{ cursor: "pointer" }}
                src={require("./assets/images/reaction-promo.png")}
              ></img>
            </div>
          </div>
        </div>
      </main>*/}
    </>
  );
}

export default Player;
