import React from "react";
import "./App.css";
import { track } from "@amplitude/analytics-browser";

function App() {
  const clickDownloadApp = () => {
    track("web - click-download-app");
    window.open("https://peekabond.com/try-peekabond", "_blank");
  };

  return (
    <>
      <header className="header">
        <img className="logo" src={require("./assets/images/logo.png")}></img>
      </header>
      <main
        style={{
          display: "flex",
          flex: 1,
          background: "#fcf9f4",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: 250 }}>
          <h2 style={{ color: "#333374" }} className="title">
            Nothing to see here...
          </h2>
          <p style={{ color: "#333374" }} className="paragraph">
            Copyright &copy; 2023 Peekabond
          </p>
        </div>
      </main>
    </>
  );
}

export default App;
